<template>
  <div class="Part">
    <!--    顶部选项按钮 开始-->
    <div ref="homeTop" class="input">
      <el-row>
        <el-button size="mini" @click="addAssetsClick" type="primary" v-if="authe.Hotel_RegionalDivision_Assets_AddingAssets">添加资产</el-button>
          <el-dialog width="1000px" v-loading="loading" :title="addAssetsForm.dialogAddElectricTitle" @close="dialogAddElectricClose" :visible.sync="addAssetsForm.dialogAddElectric" :close-on-click-modal="false" append-to-body>
            &nbsp;&nbsp;&nbsp;&nbsp;资产归属<el-input @click.native="selectAddressBelong('选择地址')" readonly :disabled="addAssetsForm.onlyFixAssets==2" v-model="addAssetsForm.assets.assetsBelong"  clearable size="mini" style="width:300px"></el-input>
            <el-form ref="electric" :model="addAssetsForm.assets" label-width="75px" :rules="rules" inline >
              <el-form-item label="资产所属" prop="saType">
                <el-select v-model="addAssetsForm.assets.saType" size="mini" clearable style="width:120px">
                  <el-option label value></el-option>
                  <el-option label="公司" value="公司"></el-option>
                  <el-option label="租赁" value="租赁"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="使用情况" prop="saUse">
                <el-select v-model="addAssetsForm.assets.saUse" size="mini" clearable style="width:120px">
                  <el-option v-for="(item,i) in addAssetsForm.saUseList" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="资产状态" prop="saStatus">
                <el-select v-model="addAssetsForm.assets.saStatus" size="mini" clearable style="width:120px">
                  <el-option v-for="(item,i) in addAssetsForm.saStatusList" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="价格" prop="saPrice" label-width="50px">
                <el-input v-model="addAssetsForm.assets.saPrice" clearable size="mini" style="width:120px"></el-input>
              </el-form-item>
              <el-form-item label="资产类型" prop="saClassify">
                <el-select @change="classifyChange" v-model="addAssetsForm.assets.saClassify" size="mini" clearable style="width:120px">
                  <el-option v-for="(item,i) in companyVars.suppliesType" :key="i" :label="item.variable" :value="item.variable"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名称" prop="saName">
                <el-select v-model="addAssetsForm.assets.saName" size="mini" clearable style="width:120px">
                  <el-option v-for="(item,i) in addAssetsForm.assetsType" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="品牌">
                <el-input v-model="addAssetsForm.assets.saBrand" clearable size="mini" style="width:120px"></el-input>
              </el-form-item>
              <el-form-item label="型号">
                <el-input v-model="addAssetsForm.assets.saModel" clearable size="mini" style="width:120px"></el-input>
              </el-form-item>
              <el-form-item v-show="addAssetsForm.onlyFixAssets==1" label="数量" label-width="50px">
                <el-input v-model="addAssetsForm.numbers" clearable size="mini" style="width:120px"></el-input>
              </el-form-item>
              <el-form-item label="供应商">
                <el-input @click.native="selectAddressBelong('选择供应商')" v-model="addAssetsForm.assets.saSupplierName" readonly size="mini" style="width:120px"></el-input>
              </el-form-item>
              <el-form-item label="备注" >
                <el-input v-model="addAssetsForm.assets.saRemarks" type="textarea" :rows="1" size="mini" style="width:310px" clearable></el-input>
              </el-form-item>
              <el-form-item v-show="addAssetsForm.onlyFixAssets==1">
                <el-button size="mini" @click="addAssets2" type="primary">添加</el-button>
                <el-button size="mini" @click="clearAssets" type="danger">清除</el-button>
              </el-form-item>
              <el-table v-show="addAssetsForm.onlyFixAssets==1" :data="addAssetsForm.assetsPARMS" style="width: 100%" size="mini" height="200px" max-height="200px">
                <el-table-column prop="assetsBelong" label="地址/项目" show-overflow-tooltip></el-table-column>
                <el-table-column prop="saType" label="所属" show-overflow-tooltip></el-table-column>
                <el-table-column prop="saClassify" label="类型"></el-table-column>
                <el-table-column prop="saUse" label="使用"></el-table-column>
                <el-table-column prop="saStatus" label="状态"></el-table-column>
                <el-table-column prop="saName" label="名称"></el-table-column>
                <el-table-column prop="saBrand" label="品牌"></el-table-column>
                <el-table-column prop="saModel" label="型号"></el-table-column>
                <el-table-column prop="saPrice" label="价值"></el-table-column>
                <el-table-column prop="saRemarks" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column prop="saSupplierName" label="供应商"></el-table-column>
                <!-- <el-table-column fixed="right" label="删除" width="80">
                  <template slot-scope="scope">
                  </template>
                </el-table-column> -->
                <el-table-column
                  label="操作"
                  width="50">
                  <template slot-scope="scope">
                    <el-button @click.native.prevent="deleteRow(scope.$index, addAssetsForm.assetsPARMS)" type="text" size="small"> 删除 </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
            <div slot="footer">
              <el-button size="mini" @click="addAssetsForm.dialogAddElectric=false">取消</el-button>
              <el-button size="mini" v-show="addAssetsForm.onlyFixAssets==1" @click="postElectric" type="primary">保存</el-button>
              <el-button size="mini" v-show="addAssetsForm.onlyFixAssets==2" @click="putElectric" type="primary">保存</el-button>
            </div>
          </el-dialog>
          <select-address
            v-if="addAssetsForm.dialogHouseList"
            :isDialogShow="addAssetsForm.dialogHouseList"
            :isTitle="addAssetsForm.dialogHouseListTitle"
            @close="selectRoomDblclick"
          >
          </select-address>
        <el-button size="mini" @click="fixAssetsClick" type="primary" v-if="authe.Hotel_RegionalDivision_Assets_ModifyAssets">修改资产</el-button>
        <el-button size="mini" @click="changeUser" type="primary" v-if="authe.Hotel_RegionalDivision_Assets_ModifyUsage">修改使用情况</el-button>
          <el-dialog width="400px" @close="dialogUserClose" :title="useStatusForm.dialogUserTitle" v-loading="loading" :visible.sync="useStatusForm.dialogUser" :close-on-click-modal="false" append-to-body>
            <el-form>
              <el-form-item label="使用情况" v-show="useStatusForm.onlySaUseNum==1">
                <el-select v-model="useStatusForm.saUse" size="mini" clearable style="width:250px">
                  <el-option v-for="(item,i) in addAssetsForm.saUseList" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="资产状态" v-show="useStatusForm.onlySaUseNum==2">
                <el-select v-model="useStatusForm.saStatus" size="mini" clearable style="width:250px">
                  <el-option v-for="(item,i) in addAssetsForm.saStatusList" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer">
              <el-button size="mini" @click="useStatusForm.dialogUser=false">取消</el-button>
              <el-button size="mini" v-show="useStatusForm.onlySaUseNum==1" @click="puUse" type="primary">保存</el-button>
              <el-button size="mini" v-show="useStatusForm.onlySaUseNum==2" @click="putStatus" type="primary">保存</el-button>
            </div>
          </el-dialog>
        <el-button size="mini" @click="changeStatus" type="primary" v-if="authe.Hotel_RegionalDivision_Assets_ModifyAssetStatus">修改资产状态</el-button>
        <el-button size="mini" @click="moveOutClick" type="primary" v-if="authe.Hotel_RegionalDivision_Assets_MigrateAssets">迁移资产</el-button>
          <el-dialog width="600px" @close="dialogMoveOutClose" v-loading="loading" title="迁移资产" :visible.sync="moveOutForm.dialogMoveOut" :close-on-click-modal="false" append-to-body>
            <el-form label-width="120px">
              <el-form-item label="迁出地址/项目">
                <el-input v-model="moveOutForm.address" size="mini" readonly style="width:400px"></el-input>
              </el-form-item>
              <el-form-item label="迁入地址/项目">
                <el-input @click.native="selectAddressBelong('选择地址')" v-model="addAssetsForm.assets.assetsBelong" size="mini" style="width:400px"></el-input>
              </el-form-item> 
              <el-form-item label="经手人">
                <el-cascader @change="handIdChange" v-model="moveOutForm.handId" :options="userNameList" filterable clearable  :props="{ expandTrigger: 'hover' }" size="mini" style="width:400px"></el-cascader>
              </el-form-item> 
              <el-form-item label="迁移原因">
                <el-input type="textarea" v-model="moveOutForm.moveReason" size="mini" style="width:400px"></el-input>
              </el-form-item> 
            </el-form>
            <div slot="footer">
              <el-button @click="moveOutForm.dialogMoveOut=false">取消</el-button>
              <el-button @click="saveMoveOut" type="primary">保存</el-button>
            </div>

          </el-dialog>
        <el-button size="mini" @click="printCodeClick" type="primary" v-if="authe.Hotel_RegionalDivision_Assets_PrintAssetCard">打印勾选资产标识卡</el-button>
          <el-dialog width="600px" style="height:600px" title="打印勾选资产标识卡" :visible.sync="printCodeForm.dialogprintCode" :close-on-click-modal="false">
            <div style="width:100%" id="code" ref="code">
              <div v-for="(item,i) in printCodeForm.urlList" :key="i" style="text-align:center;margin-top:20px">
                <p style="font-size:18px;line-height:8px">资产标识卡NO.{{item.number}}</p>
                <div style="display:flex;justify-content:center;">
                  <div>
                    <vue-qr :text="item.url" :margin="0" colorDark="#333" colorLight="#fff" :size="120"></vue-qr>
                  </div>
                  <div style="width:180px;font-size:14px;text-align:left;line-height:8px;margin-left:5px;">
                    <p>名称 {{item.name}}</p>
                    <p>品牌 {{item.brand}}</p>
                    <p>型号 {{item.model}}</p>
                    <p>地址 {{item.address}}</p>
                    <p>状态 {{item.status}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div slot="footer">
              <el-button @click="printCodeForm.dialogprintCode=false">取消</el-button>
              <el-button v-print="'#code'" type="primary">打印</el-button>
            </div>
          </el-dialog>
        <el-button size="mini" @click="advancedScreening" type="primary" icon="el-icon-search"
          v-if="authe.Hotel_RegionalDivision_Assets_UrbanAreaFilter
             || authe.Hotel_RegionalDivision_Assets_AttributionFilter
             || authe.Hotel_RegionalDivision_Assets_StatusFilter
             || authe.Hotel_RegionalDivision_Assets_NumberingFilter
             || authe.Hotel_RegionalDivision_Assets_TypesFilter
             || authe.Hotel_RegionalDivision_Assets_BrandFilter
             || authe.Hotel_RegionalDivision_Assets_ModelFilter"
          >高级筛选</el-button>
      </el-row>
      <!-- 头部筛选框开始 -->
      <el-form inline label-width="70px">
        <el-row>
          <el-form-item label="房间名称">
            <el-input @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.addCommunity" clearable size="mini" style="width:120px" ></el-input>
          </el-form-item>
          <el-form-item label="房型">
            <el-select @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.roomType" clearable size="mini" style="width:120px">
              <el-option label="请选择房型" value></el-option>
              <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="门牌号">
            <el-input @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.addDoorplateno" clearable size="mini" style="width:120px" ></el-input>
          </el-form-item>
          <el-form-item label="资产名称">
            <el-input @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.saName" clearable size="mini" style="width:100px" ></el-input>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-input @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.hsAddCommunity" clearable size="mini" style="width:120px" ></el-input>
          </el-form-item>
          <el-form-item label="使用情况">
            <el-select @change="getAllElectric(1)" v-model="homeForm.params.saUse" clearable size="mini" style="width: 120px" >
              <el-option label value></el-option>
              <el-option label="正常" value="正常"></el-option>
              <el-option label="未使用" value="未使用"></el-option>
              <el-option label="使用中" value="使用中"></el-option>
              <el-option label="已报废" value="已报废"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row v-show="homeForm.topShow">
          <el-form-item label="城区" v-if="authe.Hotel_RegionalDivision_Assets_UrbanAreaFilter">
            <el-select  @change="getAllElectric(1)" v-model="homeForm.params.addDistrict" clearable size="mini" style="width: 120px" >
              <el-option v-for="(item,index) in cityList" :key="index" :label="item.hdDistrict" :value="item.hdDistrict" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资产所属" v-if="authe.Hotel_RegionalDivision_Assets_AttributionFilter">
            <el-select @change="getAllElectric(1)" v-model="homeForm.params.saType" clearable size="mini" style="width: 120px" >
              <el-option label value></el-option>
              <el-option label="公司" value="公司"></el-option>
              <el-option label="租赁" value="租赁"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资产状态" v-if="authe.Hotel_RegionalDivision_Assets_StatusFilter">
            <el-select @change="getAllElectric(1)" v-model="homeForm.params.saStatus" clearable size="mini" style="width: 120px" >
              <el-option label value></el-option>
              <el-option label="正常" value="正常"></el-option>
              <el-option label="损坏" value="损坏"></el-option>
              <el-option label="丢失" value="丢失"></el-option>
              <el-option label="注销" value="注销"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资产编号" v-if="authe.Hotel_RegionalDivision_Assets_NumberingFilter">
            <el-input @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.saNumber" clearable size="mini" style="width:100px" ></el-input>
          </el-form-item>
          <el-form-item label="资产类型" v-if="authe.Hotel_RegionalDivision_Assets_TypesFilter">
            <el-select @change="getAllElectric(1)" v-model="homeForm.params.saClassify" size="mini" clearable style="width:120px">
              <el-option v-for="(item,i) in companyVars.suppliesType" :key="i" :label="item.variable" :value="item.variable"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌" v-if="authe.Hotel_RegionalDivision_Assets_BrandFilter">
            <el-input @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.saBrand" clearable size="mini" style="width:120px" ></el-input>
          </el-form-item>
          <el-form-item label="型号" v-if="authe.Hotel_RegionalDivision_Assets_ModelFilter">
            <el-input @input="searchOnkeyup(getAllElectric)" v-model="homeForm.params.saModel" clearable size="mini" style="width:120px" ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

  <!--      首页  表格-->
    <el-table
      @row-click="homeTableClick"
      @selection-change="homeTableSelect"
      :row-class-name="tableRowClassName"
      @row-dblclick="homeTableDblclick"
      ref="homeTable"
      :cell-style="cellStyle"
      :data="homeForm.electricList"
      :height="homeForm.tableHeight"
      fit
      show-header
      mini
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column align="center" type="selection" width="45px"></el-table-column>
      <el-table-column align="center" prop="saNumber" label="资产编号" min-width="100px" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="address" label="房屋地址/项目名称" min-width="140px" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="saType" label="所属"></el-table-column>
      <el-table-column align="center" prop="saClassify" label="类型"></el-table-column>
      <el-table-column align="center" prop="saName" label="名称" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="saBrand" label="品牌"></el-table-column>
      <el-table-column align="center" prop="saModel" label="型号"></el-table-column>
      <el-table-column align="center" prop="saPrice" label="价格"></el-table-column>
      <el-table-column align="center" prop="saDepreciationPrice" label="折旧价格"></el-table-column>
      <el-table-column align="center" prop="saStatus" label="状态"></el-table-column>
      <el-table-column align="center" prop="saUse" label="使用情况"></el-table-column>
      <el-table-column align="center" prop="saRemarks" label="备注" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="imgNum" label="图片"></el-table-column>
      <el-table-column align="center" prop="registrantName" label="登记人"></el-table-column>
      <el-table-column align="center" prop="saRegistrationTime" label="登记时间" show-overflow-tooltip></el-table-column>
    </el-table>

    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[5,10,20,40]"
      :page-size="homeForm.limit"
      :current-page="homeForm.cursor"
      :page-count="homeForm.pages"
      :total="homeForm.total"
      align="center"
      background
      @current-change="electricCurChange2"
      @size-change="handleSizeChange"
    ></el-pagination>
  
    <el-dialog width="800px" title="资产详细信息" :visible.sync="homeForm.dialogElectric" :close-on-click-modal="false" append-to-body>
      <el-button size="mini" @click="uploadPhoto" type="primary" v-if="authe.Hotel_RegionalDivision_Assets_UploadPictures">上传及查看图片</el-button>
      <el-form inline label-width="70px">
        <el-form-item label="资产归属">
          <el-input v-model="homeForm.homeRow.address" size="mini" readonly style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="资产所属">
          <el-input v-model="homeForm.homeRow.saType" size="mini" readonly style="width:100px"></el-input>
        </el-form-item> 
        <el-form-item label="使用情况">
          <el-input v-model="homeForm.homeRow.saUse" size="mini" readonly style="width:100px"></el-input>
        </el-form-item> 
        <el-form-item label="资产状态">
          <el-input v-model="homeForm.homeRow.saStatus" size="mini" readonly style="width:110px"></el-input>
        </el-form-item> 
        <el-form-item label="价格">
          <el-input v-model="homeForm.homeRow.saPrice" size="mini" readonly style="width:110px"></el-input>
        </el-form-item> 
        <el-form-item label="资产类型">
          <el-input v-model="homeForm.homeRow.saClassify" size="mini" readonly style="width:100px"></el-input>
        </el-form-item> 
        <el-form-item label="名称">
          <el-input v-model="homeForm.homeRow.saName" size="mini" readonly style="width:100px"></el-input>
        </el-form-item> 
        <el-form-item label="品牌">
          <el-input v-model="homeForm.homeRow.saBrand" size="mini" readonly style="width:300px"></el-input>
        </el-form-item> 
        <el-form-item label="型号">
          <el-input v-model="homeForm.homeRow.saModel" size="mini" readonly style="width:100px"></el-input>
        </el-form-item> 
        <el-form-item label="供应商">
          <el-input v-model="homeForm.homeRow.saSupplierName" size="mini" readonly style="width:100px"></el-input>
        </el-form-item>
        <el-form-item label="资产编号">
          <el-input v-model="homeForm.homeRow.saNumber" size="mini" readonly style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="折旧价格">
          <el-input v-model="homeForm.homeRow.saDepreciationPrice" size="mini" readonly style="width:100px"></el-input>
        </el-form-item> 
        <el-form-item label="备注">
          <el-input type="textarea" v-model="homeForm.homeRow.saRemarks" size="mini" readonly style="width:660px"></el-input>
        </el-form-item> 
      </el-form>
      <el-table @row-dblclick="saFollowUpClick" :data="saFollowUp.records" style="width: 100%" height="150px" max-height="150px" size="mini">
        <el-table-column align="center" prop="jhfFollowTime" label="进展时间" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="suStaffName" label="跟进人"></el-table-column>
        <el-table-column align="center" prop="jhfFollowRemark" :show-overflow-tooltip="true" min-width="180px" label="进展描述"></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next,total" :page-size="limit3" :current-page="cursor3"
        :total="saFollowUp.total" align="center" background @current-change="followOfAssetCurChange"></el-pagination>

      <el-dialog width="600px" title="进展详情" :visible.sync="homeForm.dialogFollowup" :close-on-click-modal="false" append-to-body>
        <table class="followtable" style="width:550px">
          <tbody>
            <tr>
              <td style="color:#222;width:60px;">跟进时间</td>
              <td colspan="3">{{homeForm.followupRow.jhfFollowTime}}</td>
            </tr>
            <tr>
              <td style="color:#222;width:60px;">跟进人</td>
              <td>{{homeForm.followupRow.suStaffName}}</td>
              <td style="color:#222;width:60px;">跟进类型</td>
              <td>{{homeForm.followupRow.jhfPaymentWay}}</td>
            </tr>
            <tr>
              <td style="color:#222;width:60px;">备注</td>
              <td colspan="3">{{homeForm.followupRow.jhfFollowRemark}}</td>
            </tr>
          </tbody>
        </table>   
      </el-dialog>
      <div style="margin-top:10px; text-align:center;">
        <el-button type="primary" @click="prvPrice" size="mini">上一条</el-button>
        <el-button size="mini" @click="homeForm.dialogElectric=false">关闭</el-button>
        <el-button type="primary" @click="nextPrice" size="mini">下一条</el-button>
      </div>
    </el-dialog>
    <!-- 上传图片组件 -->
    <common-upload ref="upload" @listenEvent="dialogImgPathClose" :dialog="dialogImgPath" :save="needSavePhoto" :photoid="photoTableId" :photorandom="photoRandom" :phototype="photoTableType"></common-upload>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  //  引入上传图片组件
  import commonUpload from '../../components/common-upload'
  import selectAddress from '../../components/selectAddress/selectAddress'
  export default {
    components: {
      'common-upload':commonUpload,
      'select-address':selectAddress
    },
    name:'assets',
    data() {
      return {
        rules:{//表单验证
          saType:[{ required: true, message: '资产所属不能为空', trigger: 'change'}],
          saUse:[{ required: true, message: '使用情况不能为空', trigger: 'change'}],
          saStatus:[{ required: true, message: '资产状态不能为空', trigger: 'change'}],
          saClassify:[{ required: true, message: '资产类型不能为空', trigger: 'change'}],
          saName:[{ required: true, message: '名称不能为空', trigger: 'change'}],
          saPrice:[{ required: true, message: '价格不能为空', trigger: 'blur'}],
        },
        loading:true,
        //上传图片
        dialogImgPath:false,
        needSavePhoto:false,
        photoTableId:'',
        photoTableType:'',
        photoRandom:'',

        homeForm: {
          topShow: false,
          dialogElectric: false,
          limit: 10,
          cursor: 1,
          total: 0,
          pages: 0,
          tableHeight: window.innerHeight - 240,
          electricList: [],
          dialogFollowup:false,
          followupRow:'',
          homeRow:'',
          homeSel:[],
          params: {
            addDistrict:'',
            addCommunity: '',
            roomType: '',
            addDoorplateno: '',
            saClassify: '',
            hsAddCommunity: '',
            saType: '',
            saNumber: '',
            saName: '',
            saBrand: '',
            saModel: '',
            saUse: '',
            saStatus: ''
          }
        },
        addAssetsForm:{
          dialogAddElectric:false,
          assets: {
            saType: '', //公司
            saClassify: '', //办公设备
            saUse: '', //未使用
            saStatus: '', //正常
            saName: '', //打印机
            saBrand: '', //A牌
            saModel: '', //型号
            saPrice: '', //价格
            saRemarks: '', //00备注00
            assetsBelong:'',//资产所属
            saSupplierName:'',//供应商名称
            saSupplier:'',//供应商Id
          },
          dialogHouseListTitle:'选择房源',
          dialogAddElectricTitle:'',
          onlyFixAssets:'1',
          assetsType:[],
          saTypeList:['','公司','业主','租赁'],
          saUseList:['','未使用','使用中','已报废'],
          saStatusList:['','正常','损坏','丢失','注销'],
          dialogHouseList:false,
          numbers: '',
          assetsPARMS:[],
          moveHsId: '',
        },
        useStatusForm:{
          saUse:'',
          saStatus:'',
          dialogUserTitle:'',
          dialogUser:false,
          onlySaUseNum:1,//使用情况唯一标识
        },
        moveOutForm:{
          address:'',
          moveReason:'',
          handId:'',
          saAgent:'',
          dialogMoveOut:false
        },
        printCodeForm:{
          dialogprintCode:false,
          urlList:[],
          selList:[],
        },
        saFollowUp:{},
        limit3:5,
        cursor3:1
      };
    },
    computed: {
      ...mapState(['userNameList', 'detailsBudgetList', 'cityList','setupList','companyVars','authe','pageAuth'])
    },
    created() {
      console.log(this.setupList)
      if (this.pageAuth['/assets']) {
        this.getAllElectric();
      } else {
        this.$router.replace('/notfind')
      }
    },
    updated() {
      this.$nextTick(() => {
        this.homeForm.tableHeight =
          window.innerHeight - this.$refs.homeTop.$el.offsetHeight - 135;
      });
    },
    methods: {
    //通用
      homeTableClick(row){//首页表格点击
        this.homeForm.homeRow=row
        console.log(this.homeForm.homeRow)
      },
      homeTableSelect(sel){//首页表格勾选
        this.homeForm.homeSel=[]
        this.printCodeForm.selList=[]
        if(sel.length>0){
          sel.forEach(item=>{
            this.homeForm.homeSel.push(item.saId)
          })
          this.printCodeForm.selList=sel
        }
      },
      homeTableDblclick(row){//首页表格双击
        this.homeForm.homeRow=row
        this.getFollowupOfAsset()
        this.homeForm.dialogElectric=true
      },
      cellStyle(row,column){//修改表格列的颜色
        if(row.column.label == '使用情况'){
          if(row.row.saUse == '使用中'){
            return 'color:green'
          }else if(row.row.saUse == '未使用'){
            return 'color:blue'
          }else if(row.row.saUse == '已报废'){
            return 'color:gray'
          }
        }
      },
    //首页 
      getAllElectric(t) {//分页查询资产
        if(t==1){
          this.homeForm.cursor=1
        }
        this.homeForm.params.limit = this.homeForm.limit;
        this.homeForm.params.cursor = this.homeForm.cursor;
        this.$axios({
          method: 'get',
          url: '/common1/companyAssets',
          params: this.homeForm.params
        }).then(res => {
          if(res.data.result.records.length>0){
            console.log(res.data.result)
            res.data.result.records.forEach(item => {
              item.addCommunity = item.addCommunity ? item.addCommunity : ''
              item.roomType = item.roomType ? item.roomType : ''
              item.addDoorplateno = item.addDoorplateno ? item.addDoorplateno : ''
              item.address = `${item.addCommunity}\u3000${item.roomType}\u3000${item.addDoorplateno}`
              item.address = item.addCity == '公区' || item.addCity == '办公室' || item.addCity == '库房' ? item.addCommunity : item.address
            }) 
          }
          this.homeForm.electricList = res.data.result.records;
          this.homeForm.total = res.data.result.total;
        }).catch(err => {
          this.isError(err,this)
        });
      },
      advancedScreening() { //高级筛选
        this.homeForm.topShow = !this.homeForm.topShow;
        this.$nextTick(() => {
          this.homeForm.tableHeight =
            window.innerHeight - this.$refs.homeTop.clientHeight - 135;
        });
      },
      electricCurChange2(val) {//资产分页
        this.homeForm.cursor = val;
        this.getAllElectric();
      },
      handleSizeChange(val) {//资产分页
        this.homeForm.limit = val;
        this.getAllElectric();
      },
      addAssetsClick(){//添加资产按钮
        this.addAssetsForm.dialogAddElectricTitle='添加资产'
        this.addAssetsForm.onlyFixAssets='1'
        this.addAssetsForm.dialogAddElectric=true
      },
      fixAssetsClick(){//修改资产按钮
        if(!this.homeForm.homeRow){
          this.$message.warning('请选择一个资产')
          return
        }
        for(let i in this.homeForm.homeRow){
          for(let k in this.addAssetsForm.assets){
            if(i==k){
              this.addAssetsForm.assets[k]=this.homeForm.homeRow[i]
            }
          }
          this.addAssetsForm.assets.assetsBelong=this.homeForm.homeRow.address
        }
        this.classifyChange()
        this.addAssetsForm.dialogAddElectricTitle='修改资产'
        this.addAssetsForm.dialogAddElectric=true
        this.addAssetsForm.onlyFixAssets='2'
      },
      putElectric(){//修改保存资产按钮
        if (!this.submitForm(['electric'], this)){
          return
        }
        this.loading=true
        this.$axios({
          method:'put',
          url:`/common1/companyAssets/${this.homeForm.homeRow.saId}`,
          data:this.addAssetsForm.assets
        }).then(res=>{
          console.log(res)
          this.addAssetsForm.dialogAddElectric=false
          this.loading=false
          this.$message.success('修改成功')
          this.getAllElectric()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      addAssets2() {//添加资产添加按钮
        if(!this.addAssetsForm.assets.assetsBelong){
          this.$message.warning('请选择资产归属')
          return
        }
        if (this.submitForm(['electric'], this)) {
          if (this.addAssetsForm.numbers > 0) {
            for (let i = 0; i < this.addAssetsForm.numbers; i++) {
              let obj = {
                saType: this.addAssetsForm.assets.saType,//公司
                saClassify: this.addAssetsForm.assets.saClassify,//办公设备
                saUse: this.addAssetsForm.assets.saUse,//未使用
                saStatus: this.addAssetsForm.assets.saStatus,//正常
                saName: this.addAssetsForm.assets.saName,//打印机
                saBrand: this.addAssetsForm.assets.saBrand,//A牌
                saModel: this.addAssetsForm.assets.saModel,//型号
                saPrice: this.addAssetsForm.assets.saPrice,//价格
                saRemarks: this.addAssetsForm.assets.saRemarks,//00备注00
                assetsBelong:this.addAssetsForm.assets.assetsBelong,//资产所属
                saSupplierName:this.addAssetsForm.assets.saSupplierName,//供应商
                saSupplier:this.addAssetsForm.assets.saSupplier,//供应商Id
              }
              this.addAssetsForm.assetsPARMS.push(obj);
            }
            console.log(this.addAssetsForm.assetsPARMS)
          } else {
            this.$message.warning('请填写数量');
          }
        } else {
          this.$message.warning('有必填项未填');
        }
      },
      postElectric() {//添加资产保存按钮
        
        if (this.addAssetsForm.assetsPARMS.length == 0) {
          this.$message.warning('请添加资产');
          return;
        }
        let obj = {
          saHouseStoreId: this.addAssetsForm.moveHsId,
          assetsPARMS: this.addAssetsForm.assetsPARMS
        };
        console.log(this.addAssetsForm.assetsPARMS)
        this.loading = true;
        this.$axios({
          method: 'post',
          url: '/common1/companyAssets/saveOrUpdateBatch',
          data: obj
        }).then(res => {
            this.loading = false;
            this.addAssetsForm.dialogAddElectric = false;
            this.$message.success('添加成功');
            this.getAllElectric()
          }).catch(err => {
            this.loading = false;
            this.isError(err,this)
          });
      },
      dialogAddElectricClose() {//添加资产弹窗关闭
        this.resetForm(['electric'], this);
        for (let i in this.addAssetsForm.assets) {
          this.addAssetsForm.assets[i] = '';
        }
        this.addAssetsForm.numbers = '';
        this.addAssetsForm.assetsPARMS = [];
      },
      uploadPhoto(){//上传图片
        this.needSavePhoto=true//需要保存按钮
        this.photoTableId=this.homeForm.homeRow.saId
        this.photoTableType='资产'
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.dialogImgPath=true
        this.$nextTick(()=>{
          this.$refs.upload.photoReflash()
        })
      },
      dialogImgPathClose(){//上传图片弹窗关闭
        this.dialogImgPath=false
      },
      selectAddressBelong(str){//选择地址
        this.addAssetsForm.dialogHouseListTitle = str
        this.addAssetsForm.dialogHouseList = true
      },
      selectRoomDblclick(row) {//双击一行房源
        if (row) {
          if (this.addAssetsForm.dialogHouseListTitle == '选择地址') {
            this.addAssetsForm.assets.assetsBelong = row.address;
            this.addAssetsForm.moveHsId = row.hsId;
          } else if (this.addAssetsForm.dialogHouseListTitle == '选择供应商') {
            this.addAssetsForm.assets.saSupplierName = row.keyAdministrator
            this.addAssetsForm.assets.saSupplier = row.houseCoding
          }
        }
        this.addAssetsForm.dialogHouseList = false;
      },
      classifyChange(){//根据资产类型获取设备名称
        this.addAssetsForm.assetsType=[]
        console.log(this.$store.state.companyVars.assetsTypeList)
        for(let i in this.$store.state.companyVars.assetsTypeList){
          if(this.addAssetsForm.assets.saClassify==this.$store.state.companyVars.assetsTypeList[i].type){
            this.addAssetsForm.assetsType=this.$store.state.companyVars.assetsTypeList[i].name
          }
        }
      },
      changeUser(){//修改使用情况按钮
        if(this.homeForm.homeSel.length==0){
          this.$message.warning('请勾选需要修改的资产')
          return
        }
        this.useStatusForm.onlySaUseNum=1
        this.useStatusForm.dialogUserTitle='修改使用情况'
        this.useStatusForm.dialogUser=true
      },
      changeStatus(){//修改使用状态按钮
        if(this.homeForm.homeSel.length==0){
          this.$message.warning('请勾选需要修改的资产')
          return
        }
        this.useStatusForm.onlySaUseNum=2
        this.useStatusForm.dialogUserTitle='修改使用状态'
        this.useStatusForm.dialogUser=true
      },
      puUse(){//修改使用情况保存按钮
        if(this.useStatusForm.saUse){
          let assetsPARMS=[]
          for(let i in this.homeForm.homeSel){
            let obj={
              saId:this.homeForm.homeSel[i],
              saUse:this.useStatusForm.saUse
            }
            assetsPARMS.push(obj)
          }
          this.loading=true
          this.$axios({
            method:'post',
            url:'/common1/companyAssets/saveOrUpdateBatch',
            data:{assetsPARMS}
          }).then(res=>{
            this.loading=false
            this.useStatusForm.dialogUser=false
            this.$message.success('修改成功')
            this.getAllElectric()
          }).catch(err => {
            this.loading = false;
            this.isError(err,this)
          });
        }else{
          this.$message.warning('请选择使用情况')
        }

      },
      putStatus(){//修改使用状态保存按钮
        if(this.useStatusForm.saStatus){
          let assetsPARMS=[]
          for(let i in this.homeForm.homeSel){
            let obj={
              saId:this.homeForm.homeSel[i],
              saStatus:this.useStatusForm.saStatus
            }
            assetsPARMS.push(obj)
          }
          this.loading=true
          this.$axios({
            method:'post',
            url:'/common1/companyAssets/saveOrUpdateBatch',
            data:{assetsPARMS}
          }).then(res=>{
            this.loading=false
            this.useStatusForm.dialogUser=false
            this.$message.success('修改成功')
            this.getAllElectric()
          }).catch(err => {
            this.loading = false;
            this.isError(err,this)
          });
        }else{
          this.$message.warning('请选择状态情况')
        }
      },
      dialogUserClose(){//修改使用情况弹窗关闭
        this.useStatusForm.saUse=''
        this.useStatusForm.saStatus=''
      },
      moveOutClick() {//迁出按钮
        if (!this.homeForm.homeRow) {
          this.$message.warning('请选择需要迁出的资产');
          return;
        }
        if(this.homeForm.homeRow.hsAddCommunity){
          this.moveOutForm.address=this.homeForm.homeRow.hsAddCommunity
        }else{
          this.moveOutForm.address=this.homeForm.homeRow.address
        }
        this.moveOutForm.dialogMoveOut = true;
      },
      handIdChange(val) {//经手人改变
        if (val) {
          this.moveOutForm.saAgent = val[2];
        }
      },
      saveMoveOut() {//迁出保存按钮
        if (!this.moveOutForm.saAgent) {
          this.$message.warning('请选择经手人');
          return;
        }
        let assetsPARMS = [];
        let obj = {
          saId: this.homeForm.homeRow.saId,
          saMoveFrom: this.moveOutForm.address,
          saNumber: this.homeForm.homeRow.saNumber,
        };
        assetsPARMS.push(obj);
        let data2 = {
          saHouseStoreId: this.addAssetsForm.moveHsId,
          saAgent: this.moveOutForm.saAgent,
          moveReason: this.moveOutForm.moveReason,
          saMoveTo:this.addAssetsForm.assets.assetsBelong,
          assetsPARMS: assetsPARMS,
        };
        this.loading = true;
        this.$axios({
          method: 'put',
          url: '/common1/companyAssets/move',
          data: data2
        }).then(res => {
          this.loading = false;
          this.moveOutForm.dialogMoveOut = false;
          this.$message.success('迁出成功');
          this.getAllElectric()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogMoveOutClose() {//迁出弹窗关闭
        this.moveOutForm.moveReason = '';
        this.moveOutForm.handId = '';
        this.moveOutForm.moveFrom = '';
        this.addAssetsForm.assets.assetsBelong=''
      },
      deleteRow(index, rows) {//添加资产删除按钮
        rows.splice(index, 1)
      },
      clearAssets(){//添加资产清除按钮
        for(let i in this.addAssetsForm.assets){
          this.addAssetsForm.assets[i]=''
        }
        this.$refs['electric'].resetFields()
        // this.resetForm(['electric'],this)
        this.addAssetsForm.numbers=null
      },
    //双击弹框 
      tableRowClassName({row,rowIndex}){//获取表格当前页的数据，并添加索引值
        row.index=rowIndex
      },
      getFollowupOfAsset(){//获取资产跟进
        this.$axios({
          method:'get',
          url:'/common1/eventFollowupRecords',
          params:{
            limit:this.limit3,
            cursor:this.cursor3,
            jhfTableId:this.homeForm.homeRow.saId,
            jhfTableType:'资产'
          }
        }).then(res=>{
          this.saFollowUp=res.data.result
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      followOfAssetCurChange(val){//资产跟进分页
        this.cursor3=val
        this.getFollowupOfAsset()
      },
      nextPrice(){//下一条
        let i=this.homeForm.homeRow.index+1
        if(i==this.homeForm.electricList.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.homeForm.homeRow=this.homeForm.electricList[i]
        this.$refs.homeTable.setCurrentRow(this.homeForm.electricList[i])
        this.homeTableDblclick(this.homeForm.homeRow)
      },
      prvPrice(){//上一条
        let i=this.homeForm.homeRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.homeForm.homeRow=this.homeForm.electricList[i]
        this.$refs.homeTable.setCurrentRow(this.homeForm.electricList[i])
        this.homeTableDblclick(this.homeForm.homeRow)
      },
      saFollowUpClick(row) {//跟进表格双击
        this.homeForm.dialogFollowup = true;
        this.homeForm.followupRow = row;
        console.log(row)
      },
      printCodeClick(){//打印资产标识卡
        let co=sessionStorage.getItem('co')
        this.printCodeForm.urlList=[]
        if(this.homeForm.homeSel.length==0){
          this.$message.warning('请勾选需要打印的资产')
          return
        }
        this.printCodeForm.selList.forEach(item=>{
          let obj={
            url: `http://fzz1.cn/b?c=${co}&a=${item.saId}`,
            number:item.saNumber,
            address:item.address,
            name:item.saName,
            status:item.saStatus,
            model:item.saModel,
            brand:item.saBrand
          } 
          this.printCodeForm.urlList.push(obj)
        })
        this.printCodeForm.dialogprintCode=true
        console.log(this.printCodeForm.urlList)
      },
    },
    
  };
</script>
<style lang='scss'>
.followtable{
  width:410px;
  border-collapse:collapse;
  border: 1px solid #ccc;
  color:#666;
  text-align:center;
  td{
    padding: 5px 0;
    border-collapse:collapse;
    border: 1px solid #ccc;
  }

}
</style>